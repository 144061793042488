import React from 'react';
import PropTypes from 'prop-types';
import './work-card.css';

const WorkCard = ({
  category,
  name,
  children,
  backgroundColor,
  isComingSoon = false,
  primary = true,
}) => {
  return (
    <div className="Work-card">
      {primary ? (
        <>
          <div className="work-card__background" style={{ backgroundColor }}>
            {isComingSoon && (
              <div className="coming-soon center">
                <span>Coming soon</span>
              </div>
            )}
          </div>
          <div className="work-image">{children}</div>
          <p className="work-category">{category}</p>
          <p className="work-title">{name}</p>
        </>
      ) : (
        <>
          <div className="secondary-card center" style={{ backgroundColor }}>
            {children}
          </div>
          <p className="work-category">{category}</p>
          <p className="work-title">{name}</p>
        </>
      )}
    </div>
  );
};

WorkCard.defaultProps = {
  isComingSoon: false,
  primary: true,
};

WorkCard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  name: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  isComingSoon: PropTypes.bool,
  primary: PropTypes.bool,
};
export default WorkCard;
