import React from 'react';
import './introduction.css';

const Introduction = () => {
  const introText = [
    'I am a Software Engineer and Data scientist with experience building scalable software applications, managing software development teams, setting up cloud infrastructure, generating insights from data, and building machine learning models to solve business problems.',
    'I love designing and implementing software infrastructure and architecture. I also enjoy telling stories with data. Learning about technology excites me and I’m always interested in finding solutions to problems. This leads me to attempt to solve problems in the best way possible.',
    'I look forward to challenges and wake up each day excited about new challenges I’ll overcome.',
  ];
  return (
    <div className="bio">
      {introText.map((text, i) => (
        <p key={i} style={{ animationDelay: `${(i + 0.1) / 10}s` }}>
          {text}
        </p>
      ))}
    </div>
  );
};

export default Introduction;
