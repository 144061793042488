import React from 'react';
import LinkTo from 'components/LinkTo';
import './nav-items.css';

const NavItems = () => (
  <ul className="nav-items">
    <li>
      <LinkTo href="https://github.com/otseobande">GITHUB</LinkTo>
    </li>
    <li>
      <LinkTo href="https://twitter.com/otseobande">TWITTER</LinkTo>
    </li>
    <li>
      <LinkTo href="https://www.linkedin.com/in/otse-obande-934ba413/">LINKEDIN</LinkTo>
    </li>
  </ul>
);

export default NavItems;
