import React, { useState } from 'react';
import SectionHeader from 'components/SectionHeader';
import Resume from 'components/Resume';
import SendButton from 'components/SendButton';
import LinkTo from 'components/LinkTo';
import './section-e.css';

const SectionE = ({ pageRef, ...rest }) => {
  const [status, setStatus] = useState('');
  const handleSubmit = (ev) => {
    setStatus('SUBMITTING');
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        setStatus('SUCCESS');
      } else {
        setStatus('ERROR');
      }
    };
    xhr.send(data);
  };
  return (
    <div className="SectionE" ref={pageRef} {...rest}>
      <div className="SectionE-content section-content-body">
        <div className="section-wrapper">
          <SectionHeader
            bgText="Like what you see?"
            foreText="Let's Chat"
            bgTextColor="#EDEDED"
            foreTextColor="#36333D"
          />
        </div>
        <form
          className="form"
          onSubmit={handleSubmit}
          action="https://formspree.io/f/xbjpkvlp"
          method="POST"
        >
          <div className="form-wrapper center-left">
            <div className="form-input__wrapper">
              <input id="name" name="name" type="text" placeholder="Your name" required />
              <input id="email" name="email" type="email" placeholder="Your email" required />
              <input
                id="phoneNumber"
                name="phoneNumber"
                type="phone"
                placeholder="Your phone number"
                required
              />
              <textarea
                id="message"
                name="message"
                placeholder="Your message"
                wrap="hard"
                rows={3}
                required
              />
            </div>
            <button className="submit-btn center" type="submit">
              <SendButton submitting={status === 'SUBMITTING' ? true : false} />
              <span>{status === 'SUBMITTING' ? 'Sending' : 'Send'}</span>
            </button>
          </div>
        </form>
        <footer className="justify-between footer">
          <div className="left">
            <h2 className="footer-header__title">Send me a mail</h2>
            <a href="mailto:otseobande@gmail.com">otseobande@gmail.com</a>
          </div>
          <div className="footer-center">
            <h2 className="footer-header__title">Social</h2>
            <ul className="socials">
              <li>
                <LinkTo href="https://github.com/otseobande">GITHUB</LinkTo>
              </li>
              <li>
                <LinkTo href="https://twitter.com/otseobande">TWITTER</LinkTo>
              </li>
              <li>
                <LinkTo href="https://www.linkedin.com/in/otse-obande-934ba413/">LINKEDIN</LinkTo>
              </li>
            </ul>
          </div>
          <div className="right">
            <h2 className="footer-header__title">Resume</h2>
            <Resume />
          </div>
        </footer>
      </div>
    </div>
  );
};

export default SectionE;
