import React from 'react';
import './index.css';

const Loader = () => {
  return (
    <svg
      className="app-loader"
      width="100"
      height="150"
      viewBox="0 0 231 320"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="app-loader-path"
        d="M206.94 3H26.3562C13.5388 3 3 13.5387 3 26.3561V214.63C3 221.466 5.84837 227.732 10.9753 232.289L101.267 310.903C110.097 318.593 122.914 318.593 131.744 310.903L222.036 232.289C227.163 227.732 230.011 221.466 230.011 214.63V26.641C230.011 13.2539 219.757 3 206.94 3Z"
        stroke="white"
        strokeMiterlimit="10"
      />
      <path
        className="app-loader-path"
        d="M116.648 3H26.3562C13.5388 3 3 13.5387 3 26.3561V214.63C3 221.466 5.84837 227.732 10.9753 232.289L101.267 310.903C105.272 314.391 110.098 316.297 115 316.621"
        stroke="#C9202D"
        strokeWidth="6"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Loader;
