import React from 'react';
import { ReactComponent as Infrastructure } from 'images/infrastructure.svg';
import { ReactComponent as DataManipulation } from 'images/data-manipulation.svg';
import { ReactComponent as Guitar } from 'images/guitar.svg';
import AwsBadge from 'images/aws.png';
import './interests-certifications.css';
import InterestList from 'components/InterestList';

const InterestsAndCertifications = () => {
  return (
    <div className="interest-certification">
      <div className="interests">
        <header>My Interests</header>
        <div className="content-wrapper">
          <InterestList text="Building Software Infrastructure">
            <Infrastructure />
          </InterestList>
          <InterestList text="Manipulating data">
            <DataManipulation />
          </InterestList>
          <InterestList text="Playing the Guitar">
            <Guitar />
          </InterestList>
        </div>
      </div>

      <div className="certifications">
        <header>My Badges</header>
        <div className="content-wrapper">
          <div className="badge-wrap">
            <img src={AwsBadge} alt="AWS badge" />
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default InterestsAndCertifications;
