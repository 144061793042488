const blogUrls = [
  '/building-a-serverless-url-shortener-with-aws-sam-and-dynamodb-ckgpdulvx07jdncs1bfoqaxw3',
  '/the-tale-of-middlemen-proxies-reverse-proxies-and-load-balancers-ckbplkttc000flgs1725e0net',
  '/fanning-out-messages-from-a-rabbitmq-queue-to-ano…bbitmq-servers-exchange-ckbdydo0900xudis1ignssvgi',
  '/setting-up-cypress-for-integration-tests-for-a-nextjs-app-on-gitlab-ci-ck9u6hz9z03rt6es1d2uu4d2m',
  '/how-our-mongodb-data-was-kidnapped-and-how-you-ca…db-from-data-kidnappers-ck7pblj7o00sx69s1ewotj08g',
  '/kubernetes-docker-swarm-and-aws-ecs-pros-and-cons-ck73so9ws073jd9s1moe72n31',
  '/setup-docker-swarm-on-digital-ocean-with-terraform-ck6zh46vv05qndfs1bl4n2w3j',
  '/using-github-actions-to-run-automated-tests-and-d…aws-ec2-with-codedeploy-ck6pkjkk202r7dfs1wddwa1l5',
  '/deploying-gitlab-review-apps-with-heroku-ck6jht24000tvdfs15yvf85dk',
  '/the-osi-burger-ck6fbhs5t012t3cs18a4xfqsm',
];

const checkPathAndRedirectToBlog = () => {
  const { pathname } = window.location;

  if (blogUrls.includes(pathname)) {
    window.location = `https://otse.hashnode.dev${pathname}`;
  }
};

export default checkPathAndRedirectToBlog;
