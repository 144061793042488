const formatMonth = (value) => {
  const appendZero = value <= 8 ? `0${value + 1}` : value + 1;
  return appendZero;
};

const formatDay = (value) => {
  const appendZero = value < 10 ? `0${value}` : value;
  return appendZero;
};

export const formatDate = (date) => {
  const getFullYear = new Date(date).getFullYear();
  const getMonth = new Date(date).getMonth();
  const getDate = new Date(date).getDate();

  return `${formatDay(getDate)}-${formatMonth(getMonth + 1)}-${getFullYear}`;
};
