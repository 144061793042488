import React from 'react';
import PropTypes from 'prop-types';
import './section-header.css';

const SectionHeader = ({ bgText, foreText, bgTextColor, foreTextColor }) => {
  return (
    <div className="Section-header">
      <h1 style={{ color: bgTextColor }}>{bgText}</h1>
      <p style={{ color: foreTextColor }}>{foreText}</p>
    </div>
  );
};

SectionHeader.defaultProps = {
  bgTextColor: '',
  foreTextColor: '',
};

SectionHeader.propTypes = {
  bgText: PropTypes.string.isRequired,
  foreText: PropTypes.string.isRequired,
  bgTextColor: PropTypes.string,
  foreTextColor: PropTypes.string,
};

export default SectionHeader;
