import React from 'react';
import { ReactComponent as Zebra } from 'images/zebra.svg';
import { ReactComponent as Andela } from 'images/andela.svg';
import { ReactComponent as FantasySports } from 'images/fantasy-sports.svg';
import { ReactComponent as Punterclash } from 'images/punterclash.svg';
import { ReactComponent as LendMe } from 'images/lendme.svg';
import { ReactComponent as Boardmanappp } from 'images/boardmanapp.svg';
import { ReactComponent as Headliner } from 'images/headliner.svg';
import { ReactComponent as DemzAnalytics } from 'images/demzanalytics.svg';
import { ReactComponent as Carryload } from 'images/carryload.svg';
import LinkTo from 'components/LinkTo';

import './sectiona-footer.css';

const SectionAFooter = () => {
  return (
    <div className="SectionA-footer">
      <span className="trusted-by">Trusted by</span>
      <ul className="footer-icons">
        <li>
          <LinkTo linkTo="https://headliner.live">
            <Headliner />
          </LinkTo>
        </li>
        <li>
          <LinkTo linkTo="https://andela.com">
            <Andela />
          </LinkTo>
        </li>
        <li>
          <LinkTo linkTo="https://www.thezebra.com">
            <Zebra />
          </LinkTo>
        </li>
        <li>
          <LinkTo linkTo="https://punterclash.com">
            <Punterclash />
          </LinkTo>
        </li>
        <li>
          <LinkTo linkTo="https://www.mylendme.co">
            <LendMe />
          </LinkTo>
        </li>
        <li>
          <LinkTo linkTo="https://fantasysports.com">
            <FantasySports />
          </LinkTo>
        </li>
        <li>
          <LinkTo linkTo="http://boardmanapp.com">
            <Boardmanappp />
          </LinkTo>
        </li>
        <li>
          <LinkTo linkTo="https://www.bloverse.com">
            <DemzAnalytics />
          </LinkTo>
        </li>
        <li>
          <LinkTo linkTo="http://carryloadng.com">
            <Carryload />
          </LinkTo>
        </li>
      </ul>
    </div>
  );
};

export default SectionAFooter;
