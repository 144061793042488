import React from 'react';
import SectionHeader from 'components/SectionHeader';
import Introduction from 'components/Introduction';
import InterestsAndCertifications from 'components/InterestsAndCertifications';
import './sectionb.css';

const SectionB = ({ pageRef, ...rest }) => {
  return (
    <div className="SectionB" ref={pageRef} {...rest}>
      <div className="SectionB-content section-content-body">
        <div className="section-wrapper">
          <SectionHeader bgText="Introduction" foreText="About" />
        </div>
        <div className="justify-between wrapper">
          <div className="left-wrapper">
            <div className="introduction-wrapper">
              <Introduction />
            </div>
            <InterestsAndCertifications />
          </div>
          <div className="right-wrapper">
            <img
              src="https://res.cloudinary.com/otse/image/upload/f_auto/v1604600851/otse_cqfr5d.svg"
              alt="otse"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionB;
