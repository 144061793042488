import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as MenuIcon } from 'images/menu.svg';
import { ReactComponent as ScrollIcon } from 'images/menu-scroll.svg';
import { ReactComponent as ObandeLogo } from 'images/obande.svg';
import { ReactComponent as CloseMenu } from 'images/menu-close.svg';
import SlideIcon from 'components/SVG/SlideIcon';
import './sidebar.css';

import { useSpring, animated } from 'react-spring';

const SideBar = ({ toggleMenu, handleNextPage, isMenuOpened, menuIndex, goToHome }) => {
  const fade = useSpring({ opacity: isMenuOpened || menuIndex === 4 ? 0 : 1 });

  return (
    <div className="Layout-sibar__container">
      <div className="Sidebar-app__logo center desktop" role="button" onClick={goToHome}>
        <ObandeLogo />
      </div>
      <div className="Layout-side__bar">
        <SlideIcon menuIndex={menuIndex} />
        <button className="menu-btn" onClick={toggleMenu}>
          {isMenuOpened ? <CloseMenu /> : <MenuIcon />}
        </button>
        <animated.button className="scroll-btn" onClick={handleNextPage} style={fade}>
          <ScrollIcon />
        </animated.button>
      </div>
    </div>
  );
};

SideBar.propTypes = {
  toggleMenu: PropTypes.func.isRequired,
  handleNextPage: PropTypes.func.isRequired,
  isMenuOpened: PropTypes.bool.isRequired,
};

export default SideBar;
