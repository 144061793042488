import React from 'react';
import './index.css';

const SendButton = ({ submitting }) => {
  return (
    <svg
      width="149"
      height="149"
      viewBox="0 0 149 149"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        className={submitting ? 'svg-send__path' : ''}
        cx="74.3104"
        cy="74.3104"
        r="73.3104"
        stroke="#EAEAEA"
        strokeWidth="2"
      />
      <path
        className={submitting ? 'svg-send__path' : ''}
        d="M74.3105 1.00012C87.0877 1.00012 99.1013 4.26883 109.561 10.0156"
        stroke="#C9202D"
        strokeWidth="2"
      />
    </svg>
  );
};

export default SendButton;
