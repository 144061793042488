import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Hello } from 'images/hello.svg';
import { ReactComponent as Scroll } from 'images/scroll-with-wrapper.svg';
import SectionAFooter from 'components/SectionAFooter';
// import { animated } from 'react-spring';
import './section-a.css';

const SectionA = ({ handleNextPage, pageRef, ...rest }) => {
  return (
    <div className="Section-a" ref={pageRef} {...rest}>
      <div className="content">
        <span className="hello">
          <Hello />
        </span>
        <div className="about-me__wrapper flex-row">
          <div className="about-me center">
            <h1>
              Otse Obande
              <strong className="dot">.</strong>
            </h1>
            <p>I build awesome digital products</p>
          </div>
          <div className="scroll center">
            <button type="button" onClick={handleNextPage}>
              <Scroll />
            </button>
          </div>
        </div>
        <SectionAFooter />
      </div>
    </div>
  );
};

SectionA.propTypes = {
  handleNextPage: PropTypes.func.isRequired,
};

export default SectionA;
