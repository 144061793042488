import React from 'react';
import SectionHeader from 'components/SectionHeader';
import WorkCard from 'components/WorkCard';
import { ReactComponent as PcApp } from 'images/pc-app.svg';
import { ReactComponent as Accelerhealth } from 'images/accelerhealth.svg';
import { ReactComponent as MazeBoard } from 'images/mazeboard.svg';
import LinkTo from 'components/LinkTo';
import './section-c.css';

const SectionC = ({ pageRef, ...rest }) => {
  return (
    <div className="Section-c" ref={pageRef} {...rest}>
      <div className="content-wrap section-content-body">
        <SectionHeader bgText="Things I have done" foreText="Projects" />
        <div className="works">
          <div>
            <LinkTo href="https://punterclash.com">
              <WorkCard name="Punterclash" category="Sports" backgroundColor="#F59120">
                <PcApp />
              </WorkCard>
            </LinkTo>
          </div>
          <div className="acel">
            <LinkTo href="https://accelerhealth.com">
              <WorkCard
                name="Accelerhealth"
                category="Health"
                backgroundColor="#DDF2F8"
                isComingSoon
              >
                <Accelerhealth />
              </WorkCard>
            </LinkTo>
          </div>
          <div className="board">
            <LinkTo href="https://otseobande.github.io/maze-problem">
              <WorkCard
                name="Maze Problem"
                category="Game"
                backgroundColor="#00B82E"
                primary={false}
              >
                <MazeBoard />
              </WorkCard>
            </LinkTo>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionC;
