import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ObandeLogoMobile } from 'images/obande2.svg';
import { ReactComponent as Menu } from 'images/menu-white.svg';
import NavItems from 'components/NavItems';
import './navbar.css';

const NavBar = ({ toggleMenu, goToHome }) => {
  return (
    <nav className="App-header center-left">
      <div className="mo-logo center mobile" role="button" onClick={goToHome}>
        <ObandeLogoMobile />
      </div>
      <div className="links">
        <NavItems />
      </div>
      <div className="menu" role="button" onClick={toggleMenu}>
        <Menu width={44} height={44} />
      </div>
    </nav>
  );
};

NavBar.defaultProps = {
  toggleMenu: () => {},
  goToHome: () => {},
};

NavBar.propTypes = {
  toggleMenu: PropTypes.func,
  goToHome: PropTypes.func,
};

export default NavBar;
