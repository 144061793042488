import React, { useState, useEffect } from 'react';
import SectionHeader from 'components/SectionHeader';
import BlogCard from 'components/BlogCard';
import { ReactComponent as ViewAll } from 'images/view-all.svg';
import Loader from 'components/Loader';
import LinkTo from 'components/LinkTo';
import './sectiond.css';

const SectionD = ({ pageRef, ...rest }) => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const res = await fetch(process.env.REACT_APP_HASHNODE_API, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: process.env.REACT_APP_HASHNODE_TOKEN,
          },
          body: JSON.stringify({
            query: `{ user(username: "${process.env.REACT_APP_HASHNODE_USERNAME}" ){publication{posts {title, cuid, coverImage, slug, dateAdded}}} }`,
          }),
        });

        const {
          data: {
            user: { publication },
          },
        } = await res.json();

        const shuffledBlog = publication.posts.sort(() => Math.random() - 0.5);
        setBlogs(shuffledBlog.slice(0, 4));
      } catch (error) {
        console.log(error);
      }
    };

    fetchBlogs();
  }, []);

  const BLOG_URL = process.env.REACT_APP_BLOG_URL;

  return (
    <div className="SectionD" ref={pageRef} {...rest}>
      <div className="SectionD-content section-content-body">
        <SectionHeader bgText="My Thoughts" foreText="Blog" />
        <div className="blogs">
          {blogs.length === 0 ? (
            <div className="center">
              <Loader />
            </div>
          ) : (
            blogs.map((blog, i) => (
              <React.Fragment key={blog.cuid}>
                <BlogCard
                  date={blog.dateAdded}
                  title={blog.title}
                  image={blog.coverImage}
                  linkTo={`${BLOG_URL}/${blog.slug}-${blog.cuid}`}
                  style={{ animationDelay: `${(i * 1) / 5}s` }}
                />
              </React.Fragment>
            ))
          )}
        </div>
        {blogs.length > 0 && (
          <LinkTo href={BLOG_URL} className="view-all center">
            <ViewAll />
          </LinkTo>
        )}
      </div>
    </div>
  );
};

export default SectionD;
