import React from 'react';
import PropTypes from 'prop-types';
import { formatDate } from 'utilities/dateFormatter';
import './blog-card.css';

const BlogCard = ({ linkTo, date, image, title, ...rest }) => {
  return (
    <div className="blog-card__wrapper" {...rest}>
      <a className="blog-card" href={linkTo} target="_blank" rel="noopener noreferrer">
        <div className="blog-card__left">
          <div className="tick" />
          <p className="date">{formatDate(date)}</p>
        </div>
        <div className="blog-card__center">
          <img src={image} alt="blog" />
        </div>
        <div className="blog-card__right">
          <p>{title}</p>
        </div>
        <div className="img-description">
          <div className="blog-card__center">
            <img src={image} alt="blog" />
          </div>
          <div className="blog-card__right">
            <p>{title}</p>
          </div>
        </div>
      </a>
      <hr className="blog-card__underline" />
    </div>
  );
};

BlogCard.propTypes = {
  linkTo: '',
};

BlogCard.propTypes = {
  linkTo: PropTypes.string,
  date: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default BlogCard;
