import React from 'react';
import PropTypes from 'prop-types';
import './interest-list.css';

const InterestList = ({ children, text }) => {
  return (
    <div className="Interest-list center-left">
      <div className="logo-wrap center">{children}</div>
      <span>{text}</span>
    </div>
  );
};

InterestList.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  text: PropTypes.string.isRequired,
};

export default InterestList;
