import React from 'react';
import PropTypes from 'prop-types';
import './menu.css';
import Resume from 'components/Resume';
import LinkTo from 'components/LinkTo';

const Menu = ({ handleSelectSection, menuIndex }) => {
  const toggleActive = (value) => {
    if (menuIndex === value) {
      return 'active';
    }
    return 'not-active';
  };

  return (
    <div className="menu-wrapper">
      <div className="menu-left center">
        <ul className="app-menu">
          {['Home', 'About', 'Projects', 'Blog', 'Contact'].map((menu, i) => (
            <li
              key={i}
              tabIndex={i}
              id={i}
              className={toggleActive(i)}
              onClick={handleSelectSection}
              style={{ animationDelay: `${(i + 0.1) / 20}s` }}
            >
              {menu}
            </li>
          ))}
        </ul>
      </div>
      <footer className="menu-footer__mobile justify-between">
        <ul className="justify-between">
          <li>
            <LinkTo href="https://github.com/otseobande">Github</LinkTo>
          </li>
          <li>
            <LinkTo href="https://twitter.com/otseobande">Twitter</LinkTo>
          </li>
          <li>
            <LinkTo href="https://www.linkedin.com/in/otse-obande-934ba413/">LinkedIn</LinkTo>
          </li>
        </ul>
      </footer>
      <footer className="menu-footer__desktop">
        <div className="wrapper">
          <div className="left">
            <h2 className="menu-footer-header__title">Send me a mail</h2>
            <a href="mailto:otseobande@gmail.com">otseobande@gmail.com</a>
          </div>
          <div className="footer-center">
            <h2 className="menu-footer-header__title">Social</h2>
            <ul className="menu-socials">
              <li>
                <LinkTo href="https://github.com/otseobande">GITHUB</LinkTo>
              </li>
              <li>
                <LinkTo href="https://twitter.com/otseobande">TWITTER</LinkTo>
              </li>
              <li>
                <LinkTo href="https://github.com/otseobande">LINKEDIN</LinkTo>
              </li>
            </ul>
          </div>
          <div className="right">
            <h2 className="menu-footer-header__title">Resume</h2>
            <Resume />
          </div>
        </div>
      </footer>
    </div>
  );
};

Menu.propTypes = {
  handleSelectSection: PropTypes.func.isRequired,
  menuIndex: PropTypes.number.isRequired,
  isOpened: PropTypes.bool.isRequired,
};

export default Menu;
