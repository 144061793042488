import React from 'react';

const LinkTo = ({ linkTo, children, ...rest }) => {
  return (
    <a
      href={linkTo}
      target="_blank"
      rel="noopener noreferrer"
      {...rest}
      style={{
        textDecoration: 'none',
      }}
    >
      {children}
    </a>
  );
};

export default LinkTo;
