import React, { useState, useEffect, useCallback, useRef } from 'react';
import NavBar from 'components/NavBar';
import SideBar from 'components/SideBar';
import Menu from 'components/Menu';
import checkPathAndRedirectToBlog from '../../utilities/checkPathAndRedirectToBlog';
import './layout.css';

import { useWheel } from 'react-use-gesture';

// Sections
import SectionA from 'components/SectionA';
import SectionB from 'components/SectionB';
import SectionC from 'components/SectionC';
import SectionD from 'components/SectionD';
import SectionE from 'components/SectionE';

const Layout = () => {
  const [menuIndex, setMenuIndex] = useState(Number(localStorage.getItem('currentPage')));
  const [isOpened, setIsOpened] = useState(false);
  const [isMobile, setIsMobile] = useState({ matches: window.innerWidth > 768 ? false : true });

  useEffect(() => {
    checkPathAndRedirectToBlog();
  }, []);

  useEffect(() => {
    const page = localStorage.getItem('currentPage');
    setMenuIndex(Number(page));
    const mediaQuery = window.matchMedia('(max-width: 768px)');

    mediaQuery.addListener(setIsMobile);
    return () => mediaQuery.removeListener(setIsMobile);
  }, []);

  const handleMenuToggle = () => setIsOpened((isOpened) => !isOpened);

  const selectSection = ({ target }) => {
    setIsOpened(false);
    setMenuIndex(Number(target.id));
  };

  const handleNextPage = () => {
    setMenuIndex((page) => (page === 4 ? 4 : page + 1));
  };

  const handlePreviousPage = () => {
    setMenuIndex((page) => (page === 0 ? 0 : page - 1));
  };

  const handleKeyPress = useCallback((e) => {
    const key = e.key || e.keyCode;

    switch (key) {
      case 'ArrowUp':
        handlePreviousPage();
        break;
      case 'ArrowLeft':
        handlePreviousPage();
        break;
      case 'ArrowRight':
        handleNextPage();
        break;
      case 'ArrowDown':
        handleNextPage();
        break;
      default:
        break;
    }
  }, []);

  const refs = [0, 1, 2, 3, 4].reduce((acc, value) => {
    acc[value] = React.createRef();
    return acc;
  }, {});

  useEffect(() => {
    if (refs[menuIndex].current) {
      refs[menuIndex].current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }

    // eslint-disable-next-line
  }, [menuIndex, isOpened]);

  useEffect(() => {
    localStorage.setItem('currentPage', menuIndex);
  }, [menuIndex]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  const scrollRef = useRef(null);
  const leftPos = (element) => element.offsetLeft;

  const bind = useWheel(
    ({ wheeling, delta: [, yM] }) => {
      const div0Position = leftPos(refs[0].current);
      const div1Position = leftPos(refs[1].current);
      const div2Position = leftPos(refs[2].current);
      const div3Position = leftPos(refs[3].current);
      const div4Position = leftPos(refs[4].current);

      const scrollPosition = scrollRef.current.scrollLeft + window.innerHeight;

      if (!wheeling && scrollRef.current) {
        if (div0Position < scrollPosition) {
          setMenuIndex(0);
        }
        if (div1Position > div0Position && div1Position < scrollPosition) {
          setMenuIndex(1);
        }
        if (div2Position > div1Position && div2Position < scrollPosition) {
          setMenuIndex(2);
        }
        if (div3Position > div2Position && div3Position < scrollPosition) {
          setMenuIndex(3);
        }
        if (div4Position > div3Position && div4Position < scrollPosition) {
          setMenuIndex(4);
        }
      }

      if (wheeling && scrollRef.current) {
        scrollRef.current.scrollBy(yM, 0);
      }
    },
    { lockDirection: true, rubberband: true }
  );

  return (
    <div className="Layout">
      <SideBar
        toggleMenu={handleMenuToggle}
        isMenuOpened={isOpened}
        menuIndex={menuIndex}
        handleNextPage={handleNextPage}
        goToHome={() => setMenuIndex(0)}
      />
      {isMobile.matches ? (
        <div className="Layout-wrapper">
          <NavBar toggleMenu={handleMenuToggle} goToHome={() => setMenuIndex(0)} />
          {isOpened && (
            <Menu handleSelectSection={selectSection} menuIndex={menuIndex} isOpened={isOpened} />
          )}

          {!isOpened && (
            <div className="component-wrapper" ref={scrollRef}>
              <SectionA handleNextPage={() => setMenuIndex(1)} pageRef={refs[0]} />
              <SectionB pageRef={refs[1]} />
              <SectionC pageRef={refs[2]} />
              <SectionD pageRef={refs[3]} />
              <SectionE pageRef={refs[4]} />
            </div>
          )}
        </div>
      ) : (
        <div className="Layout-wrapper">
          <NavBar toggleMenu={handleMenuToggle} />
          {isOpened && (
            <Menu handleSelectSection={selectSection} menuIndex={menuIndex} isOpened={isOpened} />
          )}

          {!isOpened && (
            <div className="component-wrapper" {...bind()} ref={scrollRef}>
              <SectionA handleNextPage={() => setMenuIndex(1)} pageRef={refs[0]} />
              <SectionB pageRef={refs[1]} />
              <SectionC pageRef={refs[2]} />
              <SectionD pageRef={refs[3]} />
              <SectionE pageRef={refs[4]} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Layout;
