import React from 'react';
import './index.css';

import { useSpring, animated } from 'react-spring';

const SlideIcon = ({ menuIndex }) => {
  const props = useSpring({ x: menuIndex });

  return (
    <svg width="86" height="25" viewBox="0 0 86 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="slideIcon"
        d="M1 5.75747C5.26087 11.5579 13 15.9748 26.5652 13.9747C42.3726 11.6441 59.5 -9.52527 85 7.69094"
        stroke="#0F0E14"
        strokeWidth="0.5"
        strokeLinecap="round"
      />
      <animated.circle
        className="svgCircle"
        cx="12"
        cy="12.9747"
        r="3"
        fill="#C9202D"
        style={{
          offsetDistance: props.x
            .interpolate([0, 1, 2, 3, 4], [18.4, 36.8, 55.2, 73.6, 92])
            .interpolate((x) => `${x}%`),
        }}
      />
      <animated.circle
        className="svgCircle"
        cx="12"
        cy="12.9747"
        r="1"
        fill="white"
        style={{
          offsetDistance: props.x
            .interpolate([0, 1, 2, 3, 4], [18.4, 36.8, 55.2, 73.6, 92])
            .interpolate((x) => `${x}%`),
        }}
      />
    </svg>
  );
};

export default SlideIcon;
