import React from 'react';

const Resume = () => {
  return (
    <a
      href="/files/cv.pdf"
      rel="noopener noreferrer"
      className="download-cv"
      download="OTSE OBANDE Resume.pdf"
    >
      Download
    </a>
  );
};

export default Resume;
